import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ahhoz, hogy a két fél közötti kapcsolat biztonságos, stabil
és harmadik fél által hozzáférhetetlen legyen, több kulcs használatára is szükség van.`}</p>
    <h2>{`Partner azonosítása`}</h2>
    <p>{`A Péntech által generált partner azonosító (partner-key) feladata,
hogy a Péntech rendszerében azonosítsa a beágyazó felet, és annak ellenőrzése,
hogy az előre egyeztetett domainről hívják-e meg a beágyazott modult.`}</p>
    <p>{`Létrehozása előtt a Péntechnek szükséges megküldeni hogy pontosan
milyen címen lesz beágyazva a Kockázatelemző Modul.`}</p>
    <InlineNotification kind={"warning"} mdxType="InlineNotification">
Fontos: Amennyiben az integráló fél másik oldalra vagy aloldalra mozgatná a beágyazást, 
ezt a szerződés szerinti határidőben jeleznie kell a Péntech számára, 
hogy az új címen is elérhető legyen a szolgáltatás.
    </InlineNotification>
    <h2>{`Felhasználó azonosítása`}</h2>
    <p>{`A rendeltetésszerű használat elősegítése, illetve a rosszhiszemű használat
`}<em parentName="p">{`(különös tekintettel pl.: a bot-okra)`}</em>{` kiszűrése érdekében lekérdezési korlátokat alkalmazunk.
Ehhez, szükség van a végfelhasználók megkülönböztetésére.
A beágyazó URL `}<inlineCode parentName="p">{`payload`}</inlineCode>{` eleme tartalmazza az elkódolt JSON objektumot,
aminek részleteit a `}<a parentName="p" {...{
        "href": "/quickrisk-hu/embedding"
      }}>{`Beágyazás oldalon`}</a>{` fejtjük ki.`}</p>
    <p>{`Ezt a JSON objektumot `}<strong parentName="p">{`AES-256-CBC`}</strong>{` titkosítással a Péntech által megadott titkosító kulcs
segítségével kell kódolni, majd `}<strong parentName="p">{`RFC 3986`}</strong>{` szabvány szerinti URL komponensé alakítani.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      